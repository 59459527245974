// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-picker .ant-picker-input input {
  color: #000000;
  font-size: 16px;
}
.ant-picker .ant-picker-input input::placeholder {
  color: #6b7280;
}
.ant-picker .ant-picker-input .ant-picker-suffix {
  color: #6b7280;
}
.ant-picker-ok .ant-btn {
  color: #ffffff;
  background: #4096ff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/GistDelegate/add/index.less"],"names":[],"mappings":"AAcA;EAGM,cAAA;EACA,eAAA;AAfN;AAgBM;EACE,cAAA;AAdR;AAQA;EAUM,cAAA;AAfN;AAmBA;EAEI,cAAA;EACA,mBAAA;AAlBJ","sourcesContent":["// Copyright (C) 2023-2024 StorSwift Inc.\n// This file is part of the PowerVoting library.\n\n// Licensed under the Apache License, Version 2.0 (the \"License\");\n// you may not use this file except in compliance with the License.\n// You may obtain a copy of the License at:\n// http://www.apache.org/licenses/LICENSE-2.0\n\n// Unless required by applicable law or agreed to in writing, software\n// distributed under the License is distributed on an \"AS IS\" BASIS,\n// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n// See the License for the specific language governing permissions and\n// limitations under the License.\n\n.ant-picker {\n  .ant-picker-input {\n    input {\n      color: #000000;\n      font-size: 16px;\n      &::placeholder {\n        color: #6b7280;\n      }\n    }\n    .ant-picker-suffix {\n      color: #6b7280;\n    }\n  }\n}\n.ant-picker-ok {\n  .ant-btn {\n    color: #ffffff;\n    background: #4096ff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
